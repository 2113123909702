<script lang="ts">
  import { css } from '@amedia/brick-tokens';
  import { createEventDispatcher } from 'svelte';

  import { dragScroll } from '$actions/drag-scroll';
  import { stickyScroll } from '$actions/sticky-scroll';
  import Button from '$components/style/button/button.svelte';
  import ScrollButtons from '$components/style/scroll-buttons/scroll-buttons.svelte';
  import type { SitesDomains } from '$stores/sites-store';
  import { zIndexes } from '$utils/z-indexes';

  export let ALL: string;
  export let sortedOptions: [string, string][] = [];
  export let filter = sortedOptions.length === 1 ? sortedOptions[0] : ALL;
  export let list: HTMLElement | null;
  export let sitesDomains: SitesDomains;

  let horisontalScrollPosition = 0;
  let viewport: number;

  const navStyle = css({
    maxWidth: '580px',
    top: 0,
    position: 'sticky',
    zIndex: zIndexes.filter.value,
    margin: 'calc($x4 * -1) auto $x8',
    display: 'grid',
    gridTemplateAreas: '"center"',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    alignItems: 'center',
    willChange: 'transform',
    transform: 'translate3d(0, 0, 0)',
    transition: 'transform 130ms ease-out',

    '@media screen and (min-width: 768px)': {
      maxWidth: 'unset',
    },
  });

  const listStyle = css({
    gridArea: 'center',

    backgroundColor: '$baseBg',
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'nowrap',

    marginBlock: '0',
    paddingBlock: '$x1 0',
    paddingInline: '1rem',
    scrollPaddingInline: '1rem',
    overflowX: 'auto',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&:not(.grabbed)': {
      scrollBehavior: 'smooth',
      scrollSnapType: 'x proximity',
      scrollSnapStop: 'always',

      '& > li': {
        scrollSnapAlign: 'start',
      },
    },

    '@media screen and (min-width: 768px)': {
      paddingInline: '2rem',
      scrollPaddingInline: '2rem',
    },
  });

  export const itemImageStyle = css({
    width: '24px',
    height: '24px',
    borderRadius: '99999px',
    display: 'inline-block',
    boxSizing: 'border-box',
    background: 'white',
    backgroundPosition: 'center',
    backgroundSize: 'contain',

    variants: {
      selected: {
        true: {
          border: '1px solid $buttonPrimaryActiveBg',
        },
        false: {
          border: '1px solid $buttonPrimaryBg',
        },
      },
    },
  });

  const filterButtonStyle = css({
    '& .mozart-button': {
      '& > span': {
        height: '24px',
      },
    },
  });

  const dispatch = createEventDispatcher();

  const scrollHandler = (
    e: Event & { currentTarget: EventTarget & HTMLUListElement }
  ) => {
    horisontalScrollPosition = e.currentTarget.scrollLeft;
  };

  $: filter && dispatch('filter');

  $: firstButtonWidth = list?.children[0]?.clientWidth;
  $: lastButtonWidth = list?.children[list?.children.length - 1]?.clientWidth;
</script>

<svelte:window bind:innerWidth={viewport} />

<nav class={navStyle()} use:stickyScroll>
  <ul
    class={listStyle()}
    bind:this={list}
    on:scroll={scrollHandler}
    use:dragScroll
  >
    {#if Object.values(sortedOptions).length > 1}
      <li class={filterButtonStyle()}>
        <Button
          text="Alle"
          selected={filter === ALL}
          clickLabel="feed"
          clickValue="{filter === ALL ? 'remove' : 'add'}-{ALL}"
          id="{filter === ALL ? 'remove' : 'add'}-{ALL}"
          on:click={() => {
            filter = ALL;
          }}
          size="none"
          inlinePadding="$x4"
          blockPadding="$x2"
        />
      </li>
    {/if}
    {#each sortedOptions as [key, label]}
      {@const selected =
        filter === key || Object.keys(sortedOptions).length === 1}
      <li>
        <Button
          {selected}
          text={label}
          clickLabel="feed"
          clickValue="{selected ? 'remove' : 'add'}-{encodeURI(key)}"
          id="{selected ? 'remove' : 'add'}-{encodeURI(key)}"
          on:click={() => {
            if (Object.values(sortedOptions).length > 1)
              filter = selected ? ALL : key;
          }}
          size="none"
          inlinePadding="$x4"
          blockPadding="$x2"
        >
          <div
            style="background-image: url(https://r.acdn.no/local/v3/publications/{sitesDomains[
              key
            ]}/gfx/square.svg)"
            class={itemImageStyle()}
          />
        </Button>
      </li>
    {/each}
    <li class={filterButtonStyle()}>
      <slot />
    </li>
  </ul>
  {#if viewport > 520}
    <ScrollButtons
      scrollList={list}
      id="filter-scroll-buttons"
      gridArea="center"
      scrollPosition={horisontalScrollPosition}
      {firstButtonWidth}
      {lastButtonWidth}
    />
  {/if}
</nav>
