<script lang="ts">
  import { css } from '@amedia/brick-tokens';
  import { readable } from 'svelte/store';

  import type { DomainKeys } from '$stores/sites-store';
  import { fetchFrontFeedList } from '$stores/teaser-store';
  import i18n from '$i18n/i18n.js';
  import AltPollTeaser from '$components/amedia/alt-poll-teaser/component/alt-poll-teaser.component.svelte';
  //import ESiteCarousel from '$components/common/e-site-carousel/e-site-carousel.svelte';
  import Teaserlist from '$components/common/teaserlist/teaserlist.svelte';
  import UntoldCarousel from '$components/common/untold-carousel/untold-carousel.svelte';
  import InfoBox from '$components/style/info-box/info-box.svelte';
  import Card from '$components/style/card/card.svelte';
  import Button from '$components/style/button/button.svelte';

  export let uuid: string;
  export let trackingKey: string | null;
  export let domainKeys: DomainKeys;
  export let primarySite: string;
  export let adType: 'netboard' | 'midtbanner' | 'unknown' = 'unknown';

  let seenExploreCard = !!window.localStorage.getItem('seenExploreCard');

  const debug =
    new URL(window.location.href).searchParams.get('debug') === 'true';

  const resumeId = window.sessionStorage.getItem('altResumeId');

  const options = {
    debug,
    resumeId,
  };

  let teasers: Teaserlist['teasers'];

  const errorStyle = css({
    margin: '$x6 $x4 $x20',
  });

  const displayContentStyle = css({
    display: 'contents',
  });

  const cardStyle = css({
    fontFamily: '$baseBodyS',
    '& > h2': {
      margin: 0,
      fontFamily: '$baseHeadlineL',
      fontSize: '$baseHeadlineL',
    },
    '& > p': {
      margin: '$x2 0 $x4',
    },
  });

  const getComponentByIndex = (index: number) => {
    switch (index) {
      case 3: {
        return; //ESiteCarousel;
      }
      case 6: {
        return UntoldCarousel;
      }
      case 11: {
        return AltPollTeaser;
      }
    }
    // We need to type this as any else Typescript will complain about the missing $$props type in the markup below
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return null as any;
  };

  $: teaserListPromise = fetchFrontFeedList({
    trackingKey,
    uuid,
    location: 'explore',
  });

  // Check if the user has seen the card before, do not show card if they have
  if (window.localStorage.getItem('seenExploreCard') === null) {
    seenExploreCard = false;
    window.localStorage.setItem('seenExploreCard', '1');
  }
</script>

{#if !seenExploreCard}
  <Card margin="$x8 $x16" padding="$x6">
    <div class={cardStyle()}>
      <h2>{$i18n.t('frontpage.title.explore')}</h2>
      <p>
        Her finner du noen av de beste og mest populære artiklene fra de over
        100 avisene du har tilgang til. Vi snakker engasjerende historier,
        nyttige forbrukersaker og gripende menneskemøter - for å nevne noe. God
        lesing!
      </p>
      <Button
        clickLabel="explore-card"
        clickValue="close"
        on:click={() => (seenExploreCard = true)}
        type="secondary">Lukk</Button
      >
    </div>
  </Card>
{/if}

{#await teaserListPromise then list}
  {@const teaserList = readable(list.teasers)}
  <div
    itemscope
    itemtype="https://www.adplogger.no/json-schema/CustomElement"
    class={displayContentStyle()}
  >
    <meta
      itemprop="custom-element#data"
      content={JSON.stringify({ tracer_request_id: list.id })}
    />
    <meta itemprop="custom-element#name" content="ordino_explore_feed" />
    <Teaserlist
      {teaserList}
      {options}
      {domainKeys}
      {adType}
      {primarySite}
      bind:teasers
    >
      <svelte:fragment slot="after" let:index>
        <svelte:component
          this={getComponentByIndex(index)}
          {primarySite}
          {uuid}
          parameters={{
            pollType: index === 9 ? 'rating' : 'poll',
          }}
        />
      </svelte:fragment>
    </Teaserlist>
  </div>
{:catch error}
  <div class={errorStyle()}>
    <InfoBox title={$i18n.t('log.error.title')} hasLogo>
      <p slot="text">{error.message}</p>
    </InfoBox>
  </div>
{/await}

<style>
  :global(body) {
    overflow: scroll;
    scrollbar-gutter: stable;
  }
</style>
